<template>
  <div class="page">
    <!-- 查詢 -->
    <section class="table-search">
      <div style="display: flex">
        <el-date-picker
          v-model="dateToDate"
          type="daterange"
          align="center"
          unlink-panels
          range-separator="~"
          start-placeholder="创建时间"
          end-placeholder="创建时间"
          :picker-options="pickerOptions"
          @change="reload"
          clearable
          value-format="yyyy-MM-dd"
          class="mr15"
        >
        </el-date-picker>
        <div class="search-L">
          <el-select
            v-model="tableParams.cleanStatus"
            placeholder="请选择状态"
            @change="reload"
            clearable
          >
            <el-option
              v-for="item in ObjToOpt(cleanStatusObj)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="search-R">
        <el-button type="primary" @click="goExport()">导出</el-button>
      </div>
    </section>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="创建工单时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.addDt || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="派单时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.deliverTime || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="位置" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.serviceName || "-" }}</span>
          <span>&nbsp;-&nbsp;</span>
          <span>{{ scope.row.roomName || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <span :style="{ color: cleanStatusColor[scope.row.cleanStatus] }">{{
            cleanStatusObj[scope.row.cleanStatus] || "-"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column label="保洁人" align="center">
        <template slot-scope="scope">
          <template
            v-if="scope.row.cleanStatus == 1 || scope.row.cleanStatus == 3"
          >
            暂未分配
          </template>
          <template v-if="scope.row.cleanStatus == 2">
            <span>{{ scope.row.cleanUserName || "-" }}</span>
            <span>&nbsp;|&nbsp;</span>
            <span>{{ scope.row.cleanUserMobile || "-" }}</span>
          </template>
          <template v-if="scope.row.cleanStatus == 4">
            <span v-if="scope.row.cleanType == 1">
              <span>{{ scope.row.cleanUserName || "-" }}</span>
              <span>&nbsp;|&nbsp;</span>
              <span>{{ scope.row.cleanUserMobile || "-" }}</span>
            </span>
            <span v-else>系统完成</span>
          </template>
        </template>
      </el-table-column>
      <el-table-column label="保洁开始时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.cleanStartTime }}</span>
        </template>
      </el-table-column>
      <el-table-column label="保洁结束时间" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.cleanStatus != 2">{{
            scope.row.cleanEndTime
          }}</span>
          <span v-else>暂无</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <OperateList :cleanFormId="scope.row.cleanFormId"></OperateList>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <section class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </section>
  </div>
</template>

<script>
import { getPeopleOrderList, goExportOrderList } from "@/api/clean/clean";
import { cleanStatusObj, cleanStatusColor } from "@/db/objs";
import { ObjToOpt } from "@/utils/utils";
import { pickerOptions } from "@/db/objs";
import OperateList from "./child/OperateList.vue";
export default {
  components: { OperateList },
  data() {
    return {
      ObjToOpt,
      cleanStatusObj,
      cleanStatusColor,
      pickerOptions,
      // table表格
      dateToDate: [],
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [], //表格数据
      tableParams: {
        page: 1,
        size: 10,
        kw: "", //查询关键字
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        cleanStatus: "", //状态
      },
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getPeopleOrderList(); // 【请求】表格数据
  },
  methods: {
    // 导出保洁订单
    goExport() {
      if (this.dateToDate) {
        this.tableParams.startTime = this.dateToDate[0];
        this.tableParams.endTime = this.dateToDate[1];
      } else {
        this.tableParams.startTime = "";
        this.tableParams.endTime = "";
      }
      let data = this.tableParams;
      let name = '保洁工单'
      goExportOrderList(data,name)
    },
    // 【请求】表格数据
    getPeopleOrderList() {
      if (this.dateToDate) {
        this.tableParams.startTime = this.dateToDate[0];
        this.tableParams.endTime = this.dateToDate[1];
      } else {
        this.tableParams.startTime = "";
        this.tableParams.endTime = "";
      }
      let data = this.tableParams;
      getPeopleOrderList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getPeopleOrderList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getPeopleOrderList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },
  },
};
</script>


<style lang="scss" scoped>
.page {
  padding: 0.15rem;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}

.table-search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.15rem;
}

.search-L {
  display: flex;
}
</style>