import request from "@/utils/request";
import Export from "@/utils/export";

// 获取保洁人员列表
export function getPeopleList(data) {
  return request("post", "/apm/clean/people/list", data);
}

// 新增保洁人员
export function getPeopleAdd(data) {
  return request("post", "/apm/clean/people/create", data);
}

// 编辑保洁人员
export function getPeopleEdit(data) {
  return request("post", "/apm/clean/people/update", data);
}

// 启用停用保洁人员
export function getStartStopPeople(data) {
  return request("post", "/apm/clean/people/operate", data);
}

// 获取用户二维码
export function getQrCode(data) {
  return request("post", "/apm/clean/people/getQrCode", data);
}

// 获取保洁工单列表
export function getPeopleOrderList(data) {
  return request("post", "/apm/cleanForm/listByPc", data);
}

// 获取保洁操作记录
export function getPeopleOrderRecordList(data) {
  return request("post", "/apm/cleanForm/operateRecordByPc", data);
}

// 导出保洁工单
export function goExportOrderList(data, name) {
  return Export("post", "/apm/cleanForm/excel/export", data, name);
}
